<template>
  <div>
    <!-- <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">处理进度</span>
      </div>
      <div>
        <div class="progress">
          <el-steps :space="300" :active="active">
            <el-step title="提交申请" description="2021-12-02 12:20:05"></el-step>
            <el-step title="平台审核"></el-step>
            <el-step title="平台打款"></el-step>
            <el-step title="完成"></el-step>
          </el-steps>
        </div>
      </div>
    </el-card> -->
    <el-card class="box-card">
      <div class="successNews">
        <div class="icon"><i class="el-icon-circle-check"></i></div>
        <div class="success">提交成功</div>
        <div>当前平台审核中，1-7个工作日内安排打款,工作日当日12:00之后提现的金额,</div>
        <div>次日开始,1-7个工作日安排打款。节假日顺延</div>
        <div>
          <el-button type="primary" size="medium" class="btn" @click="goList">返回列表</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 1,
    }
  },
  created() {},
  methods: {
    goList() {
      this.$router.push('withdrawalRecord')
    },
  },
}
</script>

<style scoped lang="scss">
.progress {
  ::v-deep .el-steps {
    justify-content: center;
  }
  ::v-deep .el-step__description.is-finish {
    color: #909399;
    font-size: 16px;
  }
}
.box-card {
  padding-bottom: 100px;
  .successNews {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #999999;
    .success {
      font-size: 24px;
      color: #333;
      margin: 16px 0;
    }
    .icon {
      color: #67c23a;
      font-size: 60px;
    }
    .btn {
      margin-top: 40px;
    }
  }
}
</style>
